<!--<ul *ngIf="!(navs$|async)?.loading" class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row ">-->
<!--  <li *ngFor="let nav of (navs$|async)?.data?.navigations">-->
<!--    <a pRipple [routerLink]="nav.link" class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white-alpha-90 hover:text-indigo-50 hover:bg-indigo-600 font-medium text-lg border-round cursor-pointer transition-colors transition-duration-150">-->
<!--      <i class="pi pi-home mr-2 text-white-alpha-90 text-lg"></i>-->
<!--      <span>{{nav.label | translate }}</span>-->
<!--    </a>-->
<!--  </li>-->
<!--</ul>-->
<ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row ">
  <li>
    <a pRipple routerLink="/event" class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white-alpha-90 hover:text-indigo-50 hover:bg-indigo-600 font-medium text-lg border-round cursor-pointer transition-colors transition-duration-150">
      <i class="pi pi-calendar mr-2 text-white-alpha-90 text-lg"></i>
      <span>{{'COMMONS.EVENTS' | translate}}</span>
    </a>
  </li>
  <li>
    <a pRipple routerLink="/serviceProviders" class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white-alpha-90 hover:text-indigo-50 hover:bg-indigo-600 font-medium text-lg border-round cursor-pointer transition-colors transition-duration-150">
      <i class="pi pi-id-card mr-2 text-white-alpha-90 text-lg"></i>
      <span>{{'COMMONS.SERVICE_PROVIDER' | translate}}</span>
    </a>
  </li>
  <li>
    <a pRipple routerLink="/organizer" class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white-alpha-90 hover:text-indigo-50 hover:bg-indigo-600 font-medium text-lg border-round cursor-pointer transition-colors transition-duration-150">
      <i class="pi pi-id-card mr-2 text-white-alpha-90 text-lg"></i>
      <span>{{'COMMONS.ORGANIZER' | translate}}</span>
    </a>
  </li>
  <li *ngIf="showChatButton()">
    <a pRipple routerLink="/chat/pending" class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-white-alpha-90 hover:text-indigo-50 hover:bg-indigo-600 font-medium text-lg border-round cursor-pointer transition-colors transition-duration-150">
      <i class="pi pi-id-card mr-2 text-white-alpha-90 text-lg"></i>
      <span>Chat</span>
      <p-badge *ngIf="(unreadChats$|async)?.data?.countUnreadQuoteChats as unread" [value]="unread.toString()" class="ml-2"></p-badge>
    </a>
  </li>
</ul>
