import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthLayoutComponent} from "./layouts/auth-layout/auth-layout.component";
import {DefaultLayoutComponent} from "./layouts/default-layout/default-layout.component";
import {AuthGuard} from "./auth/auth.guard";

const AUTH_ROUTES: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
];
const DASHBOARD_ROUTES: Routes = [
  {
    path: 'event',
    canActivate: [AuthGuard],
    loadChildren: () => import('./event/event.module').then(m => m.EventModule)
  },
  {
    path: 'serviceProviders',
    canActivate: [AuthGuard],
    loadChildren: () => import('./service-provider/service-provider.module').then(m => m.ServiceProviderModule)
  },
  {
    path: 'organizer',
    canActivate: [AuthGuard],
    loadChildren: () => import('./organizers/organizers.module').then(m => m.OrganizersModule)
  },
  {
    path: 'quote',
    canActivate: [AuthGuard],
    loadChildren: () => import('./quote/quote.module').then(m => m.QuoteModule)
  },
  {
    path: 'chat',
    canActivate: [AuthGuard],
    loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule)
  }
];

const routes: Routes = [
  { path: '', redirectTo: 'event', pathMatch: 'full', },
  { path: '', component: AuthLayoutComponent, children: AUTH_ROUTES },
  { path: '', component: DefaultLayoutComponent, canActivate: [AuthGuard],  children: DASHBOARD_ROUTES }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
