import { Injectable } from '@angular/core';
import {NavsGQL} from '../../generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  constructor(
    private navsGQL: NavsGQL
  ) { }

  getNavs() {
    return this.navsGQL.watch().valueChanges;
  }
}
