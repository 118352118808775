import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopbarComponent } from './topbar/topbar.component';
import { NavigationComponent } from './navigation/navigation.component';
import {SharedModule} from '../shared/shared.module';
import {StyleClassModule} from "primeng/styleclass";
import {RippleModule} from "primeng/ripple";
import {TranslateModule} from "@ngx-translate/core";



@NgModule({
  declarations: [
    TopbarComponent,
    NavigationComponent
  ],
  exports: [
    TopbarComponent
  ],
    imports: [
        SharedModule,
        StyleClassModule,
        RippleModule,
        TranslateModule
    ]
})
export class CoreModule { }
