import {Component, OnDestroy, OnInit} from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import {AuthService} from "./auth/auth.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  title = 'Covento Plattform';
  constructor(
    private primengConfig: PrimeNGConfig,
    private authService : AuthService,
    translate : TranslateService
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('de');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('de');
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.authService.isAuthenticated.subscribe(res => {
      console.log(res)
    })
  }
}
