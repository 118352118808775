import { Component, OnInit } from '@angular/core';
import {Notification, User} from "../../../generated/graphql";
import {ActivatedRoute, Router} from "@angular/router";
import {SharedService} from "../../shared/shared.service";
import {AuthService} from "../../auth/auth.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  me: any
  loading = true;
  notifications: any;
  env= environment
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private sharedService : SharedService
  ) {
  }

  ngOnInit(): void {
    this.authService.me().subscribe(({data, loading}) => {
      this.me = data.me as User;
      this.loading = loading;
    })
    this.authService.getNotifications().subscribe(({data}) => {
      this.notifications = [...data.notifications as Notification[]]
    })
  }
  readNotifications(notifications: Notification []){
    this.authService.markNotification({ids : notifications.map((value => value.id))}).subscribe(({data}) => {
      console.log(data)
    })
  }

  readNotification(id: string){
    this.authService.markNotification({ids : [id]}).subscribe(({data}) => {
      console.log(data)
    })
  }

  onLogOut(){
    this.authService.logout();
    localStorage.removeItem('token');
    this.router.navigate(['/auth/login'])
  }
}
