<!--<p-gmap [options]="options" [overlays]="overlays" [style]="{'width':'100%','height':'200px'}" ></p-gmap>-->
<div class="row">
  <div class="col-12">
    <agm-map style="height: 250px" [latitude]="latitude" [longitude]="longitude" [scrollwheel]="false" [zoom]="12">
      <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
    </agm-map>
  </div>
  <div class="col-6"  *ngIf="addressNotes">
    <span>{{addressNotes}}</span>
  </div>
</div>
