import {Component, OnInit} from '@angular/core';
import {CoreService} from '../core.service';
import {Observable} from 'rxjs';
import {ApolloQueryResult} from '@apollo/client/core';
import {
  CountUnreadQuoteChatsQuery,
  NavsQuery,
  QuoteChatStatus,
  User,
  UserRolePermissionScope,
  UserRolePermissionTarget
} from '../../../generated/graphql';
import {QuoteService} from "../../quote/quote.service";
import {AuthService} from '../../auth/auth.service';
import {ChatService} from "../../chat/chat.service";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  status: QuoteChatStatus = QuoteChatStatus.Accepted
  navs$: Observable<ApolloQueryResult<NavsQuery>> | undefined;
  activeOffer: string | undefined;
  acceptedOffer: string | undefined;
  closedOffer: string | undefined;
  activeRequest: string | undefined;
  acceptedRequest: string | undefined;
  closedRequest: string | undefined;
  me: User | undefined;

  unreadChats$: Observable<ApolloQueryResult<CountUnreadQuoteChatsQuery>> = new Observable<ApolloQueryResult<CountUnreadQuoteChatsQuery>>();
  constructor(
    private coreService: CoreService,
    private quoteService : QuoteService,
    private authService: AuthService,
    private chatService: ChatService
  ) { }

  ngOnInit(): void {
    this.authService.me().subscribe(({data}) => {
      this.me = data.me as User;
    })
    this.navs$ = this.coreService.getNavs();
    this.unreadChats$ = this.chatService.getUnreadChats();
    // this.quoteService.getUnreadChats(true , QuoteChatStatus.Pending).subscribe(({data , loading}) => {
    //   this.activeOffer = data.countUnreadQuoteChats.toString();
    // })
    // this.quoteService.getUnreadChats(true , QuoteChatStatus.Accepted).subscribe(({data , loading}) => {
    //   this.acceptedOffer = data.countUnreadQuoteChats.toString();
    // })
    // this.quoteService.getUnreadChats(true , QuoteChatStatus.Closed).subscribe(({data , loading}) => {
    //   this.closedOffer = data.countUnreadQuoteChats.toString();
    // })
    // this.quoteService.getUnreadChats(false , QuoteChatStatus.Pending).subscribe(({data , loading}) => {
    //   this.activeRequest = data.countUnreadQuoteChats.toString();
    // })
    // this.quoteService.getUnreadChats(false , QuoteChatStatus.Accepted).subscribe(({data , loading}) => {
    //   this.acceptedRequest = data.countUnreadQuoteChats.toString();
    // })
    // this.quoteService.getUnreadChats(false , QuoteChatStatus.Closed).subscribe(({data , loading}) => {
    //   this.closedRequest = data.countUnreadQuoteChats.toString();
    // })
  }

  showBadge(count : string){
    return Number(count) > 0;
  }

  showChatButton() {
    return this.me?.role?.permissions.some(p => p.target === UserRolePermissionTarget.Quote && p.scope === UserRolePermissionScope.Read)
  }

  showAllOffers(){
    return (Number(this.activeOffer) + Number(this.acceptedOffer) + Number(this.closedOffer)) > 0;
  }

  getCount() : string {
    return (Number(this.activeOffer) + Number(this.acceptedOffer) + Number(this.closedOffer)).toString()
  }

  showAllReq(){
    return (Number(this.activeRequest) + Number(this.acceptedRequest) + Number(this.closedRequest)) > 0;
  }

  getCountReq() : string {
    return (Number(this.activeRequest) + Number(this.acceptedRequest) + Number(this.closedRequest)).toString()
  }

}
