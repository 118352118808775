import { Injectable } from '@angular/core';
import {
  CountUnreadQuoteChatsGQL,
  CreateQuoteGQL,
  CreateQuoteInput,
  DeleteQuoteGQL,
  FinalizeQuoteGQL,
  FinalizeQuoteInput,
  MarkQuoteChatAsReadGQL,
  MarkQuoteChatAsReadInput,
  MyBranchesGQL,
  QuotationFilterInput,
  QuoteChatStatus,
  QuotesGQL,
  RevokeQuoteInput,
  SendQuoteMessageGQL,
  SendQuoteMessageInput,
  ShiftFilterInput,
  ShiftsGQL,
  UpdateCancelRequestStatusInput,
  UpdateEventCancelRequestGQL,
  QuoteGQL,
  NegotiateQuoteGQL,
  NegotiateQuoteInput,
  QuoteChatGQL,
  SortEnumType,
  QuoteBranchesGQL,
  QuoteChatEventsGQL
} from "../../generated/graphql";
import {WatchQueryOptionsAlone} from "apollo-angular/types";
import {map} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class QuoteService {

  constructor(
    private branchesGQL : MyBranchesGQL,
    private quoteShiftsGQL : ShiftsGQL,
    private finalizeQuote: FinalizeQuoteGQL,
    private quotesGQL: QuotesGQL,
    private deleteQuoteGQL: DeleteQuoteGQL,
    private sendQuoteMessageGQL: SendQuoteMessageGQL,
    private createQuoteGQL: CreateQuoteGQL,
    private countUnreadQuoteChatsGQL : CountUnreadQuoteChatsGQL,
    private quoteGQL: QuoteGQL,
    private negotiateQuoteGQL: NegotiateQuoteGQL,
    private updateEventCancelRequestGQL : UpdateEventCancelRequestGQL,
    private chatGQL: QuoteChatGQL,
    private quoteBranchesGQL: QuoteBranchesGQL,
    private quoteEventsGQL: QuoteChatEventsGQL
  ) { }

  getQuotes(where: QuotationFilterInput) {
    return this.quotesGQL.watch({
      where,
      order: [
        {
          creationTime : SortEnumType.Desc
        }
      ]}
     , {
      pollInterval : 5000
    }).valueChanges;
  }

  negotiateQuote(input: NegotiateQuoteInput) {
    return this.negotiateQuoteGQL.mutate({input},{
      refetchQueries: [{
        query : this.countUnreadQuoteChatsGQL.document,
        variables: {
          isOffer: true
        }
      }]
    })
  }

  getQuote(id: string) {
    return this.quoteGQL.watch({
      input: {
        id: {
          eq: id
        }
      }
    }).valueChanges
  }

  createQuote(input: CreateQuoteInput) {
    return this.createQuoteGQL.mutate({
      input
    } , {
      refetchQueries: [{
        query : this.countUnreadQuoteChatsGQL.document,
        variables: {
          isOffer: true
        }
      }]
    })
  }

  getShifts(where?: ShiftFilterInput) {
    return this.quoteShiftsGQL.watch({
      where,
    }).valueChanges.pipe(map(({data, errors, loading}) => {
        return {
          data,
          loading,
          errors
        }
      }
    ))
  }

  getBranches() {
    return this.branchesGQL.watch().valueChanges.pipe(map(({data, errors, loading}) => {
        return {
          data,
          loading,
          errors
        }
      }
    ))
  }

  finalize(input: FinalizeQuoteInput){
    return this.finalizeQuote.mutate({
      input
    }, {
      refetchQueries: [{
        query: this.quotesGQL.document
      }]
    })
  }

  deleteQuote(input: RevokeQuoteInput){
    return this.deleteQuoteGQL.mutate({
      input
    }, {
      refetchQueries: [{
        query: this.quotesGQL.document
      }]
    })
  }

  sendMessage(input: SendQuoteMessageInput){
    return this.sendQuoteMessageGQL.mutate({
      input
    }, {
      refetchQueries: []
    })
  }

  updateCancelRequest(input : UpdateCancelRequestStatusInput){
    return this.updateEventCancelRequestGQL.mutate({
      input
    }, {
      refetchQueries: [{
        query: this.quotesGQL.document
      }]
    })
  }
}
