import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {Button, ButtonModule} from "primeng/button";
import {InputText, InputTextModule} from 'primeng/inputtext';
import {Toast, ToastModule} from "primeng/toast";
import {CardModule} from "primeng/card";
import {MessageModule} from "primeng/message";
import {OverlayPanel, OverlayPanelModule} from "primeng/overlaypanel";
import {Avatar, AvatarModule} from "primeng/avatar";
import {Scroller, ScrollerModule} from "primeng/scroller";
import {RippleModule} from "primeng/ripple";
import { PageHeaderComponent } from './page-header/page-header.component';
import {SelectButton, SelectButtonModule} from "primeng/selectbutton";
import {Table, TableModule} from "primeng/table";
import {Tooltip, TooltipModule} from "primeng/tooltip";
import {Dropdown, DropdownModule} from "primeng/dropdown";
import {InputNumber, InputNumberModule} from "primeng/inputnumber";
import {InputTextarea, InputTextareaModule} from "primeng/inputtextarea";
import {Checkbox, CheckboxModule} from "primeng/checkbox";
import {StyleClassModule} from "primeng/styleclass";
import {TranslateModule} from "@ngx-translate/core";
import { GmapsComponent } from './gmaps/gmaps.component';
import {AgmCoreModule} from "@agm/core";
import {TabView, TabViewModule} from "primeng/tabview";
import {ConfirmDialogModule, ConfirmDialog} from 'primeng/confirmdialog'
import {Badge, BadgeModule} from "primeng/badge";
import {Tag, TagModule} from "primeng/tag";
import {Accordion, AccordionModule, AccordionTab} from "primeng/accordion";
import {DataViewModule} from 'primeng/dataview';
import {TabMenuModule} from "primeng/tabmenu";
import {CalendarModule} from "primeng/calendar";


@NgModule({
  declarations: [
    PageHeaderComponent,
    GmapsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    OverlayPanelModule,
    AvatarModule,
    ScrollerModule,
    SelectButtonModule,
    TableModule,
    TooltipModule,
    DropdownModule,
    InputNumberModule,
    InputTextModule,
    InputTextareaModule,
    CheckboxModule,
    StyleClassModule,
    TranslateModule,
    AgmCoreModule,
    TabViewModule,
    ButtonModule,
    ReactiveFormsModule,
    ConfirmDialogModule,
    BadgeModule,
    ToastModule,
    TagModule,
    AccordionModule
  ],
  providers: [MessageModule],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ButtonModule,
    InputTextModule,
    ToastModule,
    Toast,
    CardModule,
    MessageModule,
    OverlayPanel,
    OverlayPanelModule,
    AvatarModule,
    Avatar,
    ScrollerModule,
    Scroller,
    RippleModule,
    PageHeaderComponent,
    SelectButtonModule,
    SelectButton,
    TableModule,
    Table,
    TooltipModule,
    Tooltip,
    DropdownModule,
    Dropdown,
    InputNumberModule,
    InputNumber,
    InputTextModule,
    InputText,
    InputTextareaModule,
    InputTextarea,
    CheckboxModule,
    Checkbox,
    GmapsComponent,
    AgmCoreModule,
    TabViewModule,
    ButtonModule,
    ReactiveFormsModule,
    ConfirmDialogModule,
    ConfirmDialog,
    BadgeModule,
    TagModule,
    AccordionModule,
    StyleClassModule,
    TranslateModule,
    DataViewModule,
    TabMenuModule,
    CalendarModule
  ]
})
export class SharedModule { }

