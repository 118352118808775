import { Injectable } from '@angular/core';
import {
  BranchFilterInput, CountUnreadQuoteChatsGQL, EventFilterInput, MarkQuoteChatAsReadGQL, MarkQuoteChatAsReadInput,
  MyBranchesGQL,
  MyEventsGQL,
  QuoteBranchesGQL, QuoteChat, QuoteChatEventsGQL,
  QuoteChatFilterInput, QuoteChatGQL, QuoteChatsGQL, QuoteChatSortInput, QuoteChatStatus, ServiceProviderChatsGQL
} from '../../generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(
    private branchesGQL: QuoteBranchesGQL,
    private eventsGQL: QuoteChatEventsGQL,
    private chatsGQL: QuoteChatsGQL,
    private markQuoteChatAsReadGQL: MarkQuoteChatAsReadGQL,
    private countUnreadChatsGQL: CountUnreadQuoteChatsGQL,
    private serviceProviderChatsGQL: ServiceProviderChatsGQL
  ) { }


  getChats(where?: QuoteChatFilterInput, order?: QuoteChatSortInput[]) {
    return this.chatsGQL.watch({
      where,
      order
    }).valueChanges
  }

  getUnreadChats(status?: QuoteChatStatus, branchId? : string, eventId?: string) {
    return this.countUnreadChatsGQL.watch({
      branchId,
      eventId,
      status
    }).valueChanges
  }

  getBranches(where?: BranchFilterInput, chatFilter?: QuoteChatFilterInput) {
    return this.branchesGQL.watch({
      where,
      chatFilter
    },{
      pollInterval: 5000
    }).valueChanges
  }

  getEvents(where?: EventFilterInput, chatFilter?: QuoteChatFilterInput) {
    return this.eventsGQL.watch({
      where,
      chatFilter
    }).valueChanges;
  }

  markChatAsRead(input: MarkQuoteChatAsReadInput){
    return this.markQuoteChatAsReadGQL.mutate({
      input
    },{
      refetchQueries: [
        {
          query: this.countUnreadChatsGQL.document
        },
        {
          query: this.countUnreadChatsGQL.document,
          variables: {
            status: QuoteChatStatus.Closed
          }
        },
        {
          query: this.countUnreadChatsGQL.document,
          variables: {
            status: QuoteChatStatus.Pending
          }
        },
        {
          query: this.countUnreadChatsGQL.document,
          variables: {
            status: QuoteChatStatus.Accepted
          }
        }
      ]
    })
  }
}
