<div class="p-bg-primary" style="height:250px">
  <div class="py-3 px-5 flex align-items-center justify-content-between relative lg:static" style="min-height: 80px">
    <img src="assets/images/logo-white.svg" alt="Image" height="60" class="mr-0 lg:mr-6">
    <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1">
      <app-navigation></app-navigation>
      <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 border-indigo-400 lg:border-top-none">
        <div class="px-3">
          <i class="pi pi-th-large text-white text-lg"></i>
          <a [href]="env.hrUrl" target="_blank" class="text-lg ml-2 text-white">Covento Planning</a>
        </div>
        <div class="px-3">
          <i class="pi pi-th-large text-white text-lg"></i>
          <a [href]="env.myCoventoUrl" target="_blank" class="text-lg ml-2 text-white">My Covento</a>
        </div>
        <li class="border-top-1 border-indigo-400 lg:border-top-none">
          <button  pButton (click)="notif.toggle($event)">
            <i class="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0" pBadge [value]="notifications?.length?.toString()"></i>
          </button>
          <p-overlayPanel #notif>
            <ng-template pTemplate>
              <div class="h-25rem overflow-y-auto overflow-x-hidden" ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <div>
                  <div class="text-xl font-medium text-900 mb-1">Benachrichtigungen</div>
                  <div class="font-medium text-500 mb-3">
                    <a type="button" class="small font-normal cursor-pointer text-500" (click)="readNotifications(notifications)">Alle als gelesen markieren</a>
                  </div>
                  <hr />
                  <div *ngFor="let notification of notifications">
                    <div class="grid p-bg-gray-100 border-round border-gray-300 mt-2 h-6rem">
                      <div class="p-bg-primary col-1 h-full flex flex-column align-items-center justify-content-center">
                        <i class="pi pi-megaphone text-white text-xl"></i>
                      </div>
                      <div class="col-11">
                        <a class="text-yellow-900 font-medium text-lg mb-2 line-height-1 cursor-pointer"
                           [routerLink]="notification.link">
                          {{notification.text}}
                        </a>
                        <div class="font-size-12 text-muted">
                          <span>{{notification.creationTime | date: 'dd.MM.yyyy HH:mm'}}</span>
                          <i class="pi pi-eye p-color-primary ms-2 font-size-18 cursor-pointer ml-2" (click)="readNotification(notification.id)"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </p-overlayPanel>
        </li>
        <li class="border-top-1 border-indigo-400 lg:border-top-none">
          <button  pButton (click)="op.toggle($event)">
            <p-avatar *ngIf="!me?.account?.logoUrl" shape="circle" [style]="{'background-color': '#f5f6fb', 'color': 'black'}"
                      [label]="me?.firstName.charAt(0) +  me?.lastName.charAt(0)" size="xLarge"></p-avatar>
            <p-avatar *ngIf="me?.account?.logoUrl" [image]="env.restUrl + me?.account?.logoUrl" shape="circle"
                       size="xLarge"></p-avatar>
            <span class="ml-2 text-" *ngIf="!loading">{{me?.firstName}}</span>
            <i class="pi pi-angle-down font-size-20 mb-0 text-dark"></i>
          </button>
          <p-overlayPanel #op>
            <ng-template pTemplate>
              <div class="px-3 flex flex-row justify-content-start align-items-center">
                <p-avatar *ngIf="!me?.account?.logoUrl" shape="circle" [style]="{'background-color': '#f5f6fb', 'color': 'black'}"
                          [label]="me?.firstName.charAt(0) +  me.lastName.charAt(0)" size="xLarge"></p-avatar>
                <p-avatar *ngIf="me?.account?.logoUrl" [image]="env.restUrl + me?.account?.logoUrl" shape="circle"
                          size="xLarge"></p-avatar>
                <div class="flex flex-column mt-2">
                  <span class="ml-2 text-dark text-lg font-medium" *ngIf="!loading">{{me.firstName}} {{me.lastName}}</span>
                  <span class="ml-2 text-gray-700 text-sm" *ngIf="!loading">{{me.email}}</span>
                </div>
              </div>
              <hr/>
              <ul class="list-none px-3">
                <li class="mt-2 mb-2 cursor-pointer" (click)="onLogOut()">
                  <i class="pi pi-sign-out mb-0 text-lg p-color-gray-500"></i>
                  <span class="text-lg ml-2">Logout</span>
                </li>
              </ul>
            </ng-template>
          </p-overlayPanel>
        </li>
      </ul>
    </div>
  </div>
  <ul class="list-none py-3 px-5 m-0 flex align-items-center font-medium overflow-x-auto border-top-1 border-black-alpha-10">
    <li class="pr-3">
      <a class="cursor-pointer">
        <i class="pi pi-home text-indigo-200"></i>
      </a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-indigo-200"></i>
    </li>
<!--    <li class="px-2">-->
<!--      <a class="cursor-pointer text-indigo-200 white-space-nowrap">Level 3</a>-->
<!--    </li>-->
<!--    <li class="px-2">-->
<!--      <i class="pi pi-angle-right text-indigo-100"></i>-->
<!--    </li>-->
<!--    <li class="px-2">-->
<!--      <a class="cursor-pointer text-indigo-200 white-space-nowrap">Level 2</a>-->
<!--    </li>-->
<!--    <li class="px-2">-->
<!--      <i class="pi pi-angle-right text-indigo-200"></i>-->
<!--    </li>-->
<!--    <li class="px-2">-->
<!--      <a class="cursor-pointer text-indigo-100 white-space-nowrap">Level 1</a>-->
<!--    </li>-->
  </ul>
</div>
